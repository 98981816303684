var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Context',{attrs:{"max-height-if-outside-viewport":true}},[(_vm.activeDecorations.length === 0)?_c('ViewDecoratorList',{attrs:{"database":_vm.database,"view":_vm.view},on:{"select":function($event){return _vm.addDecoration($event)}}}):_c('div',{staticClass:"decorator-context"},[_c('div',{directives:[{name:"auto-overflow-scroll",rawName:"v-auto-overflow-scroll"}],staticClass:"decorator-context__list"},_vm._l((_vm.activeDecorations),function(dec){return _c('div',{key:dec.decoration.id,staticClass:"decorator-context__decorator"},[_c('div',{staticClass:"decorator-context__decorator-header"},[_c('div',{staticClass:"decorator-context__decorator-header-info"},[_c('ViewDecoratorItem',{attrs:{"decorator-type":dec.decoratorType}})],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(dec.valueProviderType),expression:"dec.valueProviderType"}],staticClass:"decorator-context__decorator-header-select"},[(dec.valueProviderType)?_c('Picker',{attrs:{"icon":dec.valueProviderType.getIconClass(),"name":dec.valueProviderType.getName(),"small":""},on:{"select":function($event){return _vm.selectValueProvider(dec.decoration, $event)}},scopedSlots:_vm._u([{key:"default",fn:function({ hidePicker }){return [_c('div',{staticClass:"decorator-context__decorator-value-provider-select-list"},[_c('DecoratorValueProviderList',{attrs:{"decoration":dec.decoration},on:{"select":function($event){;[
                        _vm.selectValueProvider(dec.decoration, $event),
                        hidePicker(),
                      ]}}})],1)]}}],null,true)}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"decorator-context__decorator-header-trash"},[_c('ButtonIcon',{attrs:{"icon":"iconoir-bin"},on:{"click":function($event){return _vm.removeDecoration(dec.decoration)}}})],1)]),_vm._v(" "),(dec.valueProviderType)?_c(dec.valueProviderType.getFormComponent(),{tag:"component",attrs:{"view":_vm.view,"table":_vm.table,"database":_vm.database,"fields":_vm.fields,"read-only":_vm.readOnly,"options":dec.decoration.value_provider_conf},on:{"update":function($event){return _vm.updateDecorationOptions(dec.decoration, $event)}}}):_c('DecoratorValueProviderList',{attrs:{"decoration":dec.decoration,"direction":'row',"read-only":_vm.readOnly || dec.decoration._.loading},on:{"select":function($event){return _vm.selectValueProvider(dec.decoration, $event)}}})],1)}),0),_vm._v(" "),(
        _vm.$hasPermission(
          'database.table.view.create_decoration',
          _vm.view,
          _vm.database.workspace.id
        )
      )?_c('div',{staticClass:"decorator-context__footer"},[_c('span',{ref:"addDecoratorLink"},[_c('ButtonText',{staticClass:"choose-select-field__link margin-right-auto",attrs:{"icon":"iconoir-plus"},on:{"click":function($event){return _vm.$refs.selectDecoratorContext.toggle(
              _vm.$refs.addDecoratorLink,
              'bottom',
              'left',
              4
            )}}},[_vm._v("\n          "+_vm._s(_vm.$t('viewDecoratorContext.addDecorator'))+"\n        ")])],1),_vm._v(" "),_c('SelectViewDecoratorContext',{ref:"selectDecoratorContext",attrs:{"database":_vm.database,"view":_vm.view},on:{"select":function($event){;[_vm.$refs.selectDecoratorContext.hide(), _vm.addDecoration($event)]}}})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }