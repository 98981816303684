var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.value)?_c('div',[(_vm.value.length === 0)?_c('div',[_c('div',{staticClass:"filters__none"},[_c('div',{staticClass:"filters__none-title"},[_vm._v("\n        "+_vm._s(_vm.$t('localBaserowTableServiceConditionalForm.noFilterTitle'))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"filters__none-description"},[_vm._v("\n        "+_vm._s(_vm.$t('localBaserowTableServiceConditionalForm.noFilterText'))+"\n      ")])])]):_vm._e(),_vm._v(" "),_c('ViewFieldConditionsForm',{staticClass:"filters__items",attrs:{"filters":_vm.getSortedDataSourceFilters(),"disable-filter":false,"filter-type":_vm.filterType,"fields":_vm.dataSourceFields,"read-only":false,"prepare-value":_vm.prepareValue},on:{"deleteFilter":function($event){return _vm.deleteFilter($event)},"updateFilter":function($event){return _vm.updateFilter($event)},"updateFilterType":function($event){return _vm.$emit('update:filterType', $event.value)}},scopedSlots:_vm._u([{key:"filterInputComponent",fn:function({
        slotProps: { filter, filterType: propFilterType },
      }){return [(filter.value_is_formula && propFilterType.hasEditableValue)?_c('InjectedFormulaInput',{staticClass:"filters__value--formula-input",attrs:{"placeholder":_vm.$t(
            'localBaserowTableServiceConditionalForm.formulaFilterInputPlaceholder'
          )},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}}):_vm._e()]}},{key:"afterValueInput",fn:function({
        slotProps: { filter, filterType: propFilterType, emitUpdate },
      }){return [(
          propFilterType.hasEditableValue && !propFilterType.isDeprecated()
        )?_c('a',{staticClass:"filters__value--formula-toggle",class:{
          'filters__value-formula-toggle--disabled': !filter.value_is_formula,
        },attrs:{"title":!filter.value_is_formula
            ? _vm.$t('localBaserowTableServiceConditionalForm.useFormulaForValue')
            : _vm.$t('localBaserowTableServiceConditionalForm.useDefaultForValue')},on:{"click":function($event){return _vm.handleFormulaToggleClick(filter, emitUpdate)}}},[_c('i',{staticClass:"iconoir-sigma-function"})]):_vm._e()]}}],null,false,2301619787)}),_vm._v(" "),_c('div',{staticClass:"filters_footer"},[(!_vm.tableLoading)?_c('ButtonText',{staticClass:"filters__add",attrs:{"type":"secondary","size":"small","icon":"iconoir-plus"},on:{"click":function($event){$event.preventDefault();return _vm.addFilter()}}},[_vm._v("\n      "+_vm._s(_vm.$t('localBaserowTableServiceConditionalForm.addFilter'))+"\n    ")]):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }