export default (client) => {
  return {
    getPage(path) {
      const baseURL = new URL(client.defaults.baseURL)
      baseURL.pathname = '/cms-api'
      return client(
        {
          method: 'get',
          url: '/pages/',
          baseURL: baseURL.toString(),
          params: { html_path: path },
        },
        {
          params: { html_path: path },
        }
      )
    },
    getSaaSLayout() {
      const baseURL = new URL(client.defaults.baseURL)
      baseURL.pathname = '/cms-api'
      return client({
        method: 'get',
        url: '/saas-layout/',
        baseURL: baseURL.toString(),
      })
    },
  }
}
