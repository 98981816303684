var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dropdown dropdown--small",class:{
    'dropdown--floating': !_vm.showInput,
    'dropdown--disabled': _vm.disabled,
  },attrs:{"tabindex":_vm.realTabindex},on:{"focusin":function($event){return _vm.show()},"focusout":function($event){return _vm.focusout($event)}}},[(_vm.showInput)?_c('a',{staticClass:"dropdown__selected",on:{"click":function($event){return _vm.show()}}},[(_vm.displayName !== null)?[_c('span',{staticClass:"dropdown__selected-text"},[_vm._v(_vm._s(_vm.displayName))])]:[_vm._v(_vm._s(_vm.notSelectedText === null ? _vm.$t('action.makeChoice') : _vm.notSelectedText))],_vm._v(" "),_c('i',{staticClass:"dropdown__toggle-icon iconoir-nav-arrow-down"})],2):_vm._e(),_vm._v(" "),_c('div',{ref:"itemsContainer",staticClass:"dropdown__items",class:{
      hidden: !_vm.open,
      'dropdown__items--fixed': _vm.fixedItemsImmutable,
    }},[(_vm.showSearch)?_c('div',{staticClass:"select__search"},[_c('i',{staticClass:"select__search-icon iconoir-search"}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.query),expression:"query"}],ref:"search",staticClass:"select__search-input",attrs:{"type":"text","tabindex":"0","placeholder":_vm.searchText === null ? _vm.$t('action.search') : _vm.searchText},domProps:{"value":(_vm.query)},on:{"input":[function($event){if($event.target.composing)return;_vm.query=$event.target.value},_vm.search]}})]):_vm._e(),_vm._v(" "),_c('ul',{directives:[{name:"auto-overflow-scroll",rawName:"v-auto-overflow-scroll"}],ref:"items",staticClass:"select__items",class:{ 'select__items--no-max-height': _vm.fixedItemsImmutable },attrs:{"tabindex":""},on:{"scroll":_vm.scroll}},[(_vm.addEmptyItem)?_c('DropdownItem',{attrs:{"name":_vm.emptyItemDisplayName,"value":null}}):_vm._e(),_vm._v(" "),_vm._l((_vm.results),function(result){return _c('DropdownItem',{key:result[_vm.idName],attrs:{"name":result[_vm.valueName],"value":result[_vm.idName]}})}),_vm._v(" "),(_vm.loading)?_c('div',{staticClass:"select__items-loading"}):_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }