var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"tree__sub",class:{ active: _vm.page._.selected }},[_c('a',{staticClass:"tree__sub-link",attrs:{"title":_vm.page.name,"href":_vm.resolvePageHref(_vm.builder, _vm.page)},on:{"mousedown":function($event){$event.preventDefault();},"click":function($event){$event.preventDefault();return _vm.selectPage(_vm.builder, _vm.page)}}},[_c('Editable',{ref:"rename",attrs:{"value":_vm.page.name},on:{"change":function($event){return _vm.renamePage(_vm.builder, _vm.page, $event)}}})],1),_vm._v(" "),(_vm.showOptions)?_c('a',{directives:[{name:"show",rawName:"v-show",value:(!_vm.builder._.loading),expression:"!builder._.loading"}],staticClass:"tree__options",on:{"click":function($event){return _vm.$refs.context.toggle($event.currentTarget, 'bottom', 'right', 0)},"mousedown":function($event){$event.stopPropagation();}}},[_c('i',{staticClass:"baserow-icon-more-vertical"})]):_vm._e(),_vm._v(" "),_c('Context',{ref:"context",attrs:{"overflow-scroll":true,"max-height-if-outside-viewport":true}},[_c('div',{staticClass:"context__menu-title"},[_vm._v(_vm._s(_vm.page.name)+" ("+_vm._s(_vm.page.id)+")")]),_vm._v(" "),_c('ul',{staticClass:"context__menu"},[(
          _vm.$hasPermission('builder.page.update', _vm.page, _vm.builder.workspace.id)
        )?_c('li',{staticClass:"context__menu-item"},[_c('a',{staticClass:"context__menu-item-link",on:{"click":function($event){return _vm.enableRename()}}},[_c('i',{staticClass:"context__menu-item-icon iconoir-edit-pencil"}),_vm._v("\n          "+_vm._s(_vm.$t('action.rename'))+"\n        ")])]):_vm._e(),_vm._v(" "),(
          _vm.$hasPermission('builder.page.duplicate', _vm.page, _vm.builder.workspace.id)
        )?_c('li',{staticClass:"context__menu-item"},[_c('a',{staticClass:"context__menu-item-link",class:{
            'context__menu-item-link--loading': _vm.duplicateLoading,
            disabled: _vm.deleteLoading || _vm.duplicateLoading,
          },on:{"click":function($event){return _vm.duplicatePage()}}},[_c('i',{staticClass:"context__menu-item-icon iconoir-copy"}),_vm._v("\n          "+_vm._s(_vm.$t('action.duplicate'))+"\n        ")])]):_vm._e(),_vm._v(" "),(
          _vm.$hasPermission('builder.page.delete', _vm.page, _vm.builder.workspace.id)
        )?_c('li',{staticClass:"context__menu-item context__menu-item--with-separator"},[_c('a',{staticClass:"context__menu-item-link context__menu-link--delete",class:{ 'context__menu-item-link--loading': _vm.deleteLoading },on:{"click":function($event){return _vm.deletePage()}}},[_c('i',{staticClass:"context__menu-item-icon iconoir-bin"}),_vm._v("\n          "+_vm._s(_vm.$t('action.delete'))+"\n        ")])]):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }