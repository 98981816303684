var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"fieldContextAnchor",staticClass:"grid-view-aggregation",class:{
    'read-only': !_vm.userCanMakeAggregations,
  },on:{"click":function($event){$event.preventDefault();_vm.userCanMakeAggregations &&
      _vm.$refs[`fieldContext`].toggle(
        _vm.$refs.fieldContextAnchor,
        'top',
        'right',
        10
      )}}},[(_vm.viewAggregationType)?_c(_vm.viewAggregationType.getComponent(),{tag:"component",attrs:{"loading":_vm.loading,"aggregation-type":_vm.viewAggregationType,"value":_vm.value}}):_vm._e(),_vm._v(" "),_c('Context',{ref:`fieldContext`},[_c('ul',{staticClass:"select__items"},[_c('li',{staticClass:"select__item select__item--no-options",class:{ active: !_vm.viewAggregationType }},[_c('a',{staticClass:"select__item-link",on:{"click":function($event){return _vm.selectAggregation('none')}}},[_c('div',{staticClass:"select__item-name"},[_c('span',{staticClass:"select__item-name-text"},[_vm._v(_vm._s(_vm.$t('common.none')))])])]),_vm._v(" "),(!_vm.viewAggregationType)?_c('i',{staticClass:"select__item-active-icon iconoir-check"}):_vm._e()]),_vm._v(" "),_vm._l((_vm.viewAggregationTypes),function(viewAggregation){return _c('li',{key:viewAggregation.getType(),staticClass:"select__item select__item--no-options",class:{
          active: viewAggregation === _vm.viewAggregationType,
        }},[_c('a',{staticClass:"select__item-link",on:{"click":function($event){_vm.selectAggregation(viewAggregation.getType())}}},[_c('div',{staticClass:"select__item-name"},[_c('span',{staticClass:"select__item-name-text"},[_vm._v(_vm._s(viewAggregation.getName()))])])]),_vm._v(" "),(viewAggregation === _vm.viewAggregationType)?_c('i',{staticClass:"select__item-active-icon iconoir-check"}):_vm._e()])})],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }