var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Context',{staticClass:"data-source-context",class:{ 'context--loading-overlay': _vm.state === 'loading' },attrs:{"overflow-scroll":true,"max-height-if-outside-viewport":true},on:{"shown":_vm.shown}},[(_vm.state === 'loaded')?[(_vm.dataSources.length > 0)?_c('div',_vm._l((_vm.dataSources),function(dataSource){return _c('ReadOnlyForm',{key:dataSource.id,attrs:{"read-only":!_vm.$hasPermission(
            'builder.page.data_source.update',
            dataSource,
            _vm.workspace.id
          )}},[_c('DataSourceForm',{ref:`dataSourceForm_${dataSource.id}`,refInFor:true,attrs:{"id":dataSource.id,"builder":_vm.builder,"data-source":dataSource,"page":_vm.page,"default-values":dataSource,"integrations":_vm.integrations,"loading":_vm.dataSourcesLoading.includes(dataSource.id)},on:{"delete":function($event){return _vm.deleteDataSource(dataSource)},"values-changed":function($event){return _vm.updateDataSource(dataSource, $event)}}})],1)}),1):[_c('div',{staticClass:"data-source-context__none"},[_c('div',{staticClass:"data-source-context__none-title"},[_vm._v("\n          "+_vm._s(_vm.$t('dataSourceContext.noDataSourceTitle'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"data-source-context__none-description"},[_vm._v("\n          "+_vm._s(_vm.$t('dataSourceContext.noDataSourceMessage'))+"\n        ")])])],_vm._v(" "),(
        _vm.$hasPermission('builder.page.create_data_source', _vm.page, _vm.workspace.id)
      )?_c('ButtonText',{attrs:{"type":"secondary","icon":"iconoir-plus","size":"small","loading":_vm.creationInProgress},on:{"click":function($event){return _vm.createDataSource()}}},[_vm._v("\n      "+_vm._s(_vm.$t('dataSourceContext.addDataSource'))+"\n    ")]):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }