var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FormElement',{staticClass:"control",class:{
    'control--horizontal':
      _vm.horizontal || _vm.horizontalNarrow || _vm.horizontalVariable,
    'control--horizontal-narrow': _vm.horizontalNarrow,
    'control--horizontal-variable': _vm.horizontalVariable,
    'control--messages': _vm.hasMessages,
    'control--after-input': _vm.hasAfterInputSlot,
    'control--error': _vm.hasError,
  },attrs:{"error":_vm.hasError}},[(_vm.label && !_vm.hasLabelSlot)?_c('label',{staticClass:"control__label",class:{ 'control__label--small': _vm.smallLabel },attrs:{"for":_vm.id}},[_c('span',[_vm._v(_vm._s(_vm.label)+" ")]),_vm._v(" "),(!_vm.required)?_c('span',{staticClass:"control__required"},[_vm._v("Optional")]):_vm._e()]):_vm._e(),_vm._v(" "),(!_vm.label && _vm.hasLabelSlot)?_c('span',{staticClass:"control__label",class:{ 'control__label--small': _vm.smallLabel }},[_vm._t("label")],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"control__wrapper"},[_c('div',{staticClass:"control__elements",class:{ 'control__elements--flex': _vm.$slots['after-input'] }},[_c('div',{staticClass:"flex-grow-1"},[_vm._t("default")],2),_vm._v(" "),_vm._t("after-input")],2),_vm._v(" "),(_vm.hasMessages)?_c('div',{staticClass:"control__messages"},[(_vm.helperText || _vm.hasHelperSlot)?_c('p',{staticClass:"control__helper-text"},[_vm._v("\n        "+_vm._s(_vm.helperText)+"\n\n        "),(_vm.hasHelperSlot)?_vm._t("helper"):_vm._e()],2):_vm._e(),_vm._v(" "),(_vm.hasError)?_c('p',{staticClass:"control__messages--error"},[(_vm.hasErrorSlot)?_vm._t("error"):(_vm.errorMessage)?[_vm._v(_vm._s(_vm.errorMessage))]:_vm._e()],2):_vm._e(),_vm._v(" "),(_vm.hasWarningSlot)?_c('p',{staticClass:"control__messages--warning"},[_vm._t("warning")],2):_vm._e()]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }