var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Context',{attrs:{"overflow-scroll":true,"max-height-if-outside-viewport":true}},[(Object.keys(_vm.subject).length > 0)?[_c('div',{staticClass:"context__menu-title"},[_c('div',{staticClass:"edit-role-context__header"},[_c('div',[_vm._v("\n          "+_vm._s(_vm.$t('membersSettings.membersTable.columns.role'))+"\n        ")]),_vm._v(" "),(_vm.atLeastOneBillableRole)?_c('div',{staticClass:"edit-role-context__header-link"},[_c('i',{staticClass:"iconoir-book"}),_vm._v(" "),_c('a',{attrs:{"href":"https://baserow.io/user-docs/subscriptions-overview#who-is-considered-a-user-for-billing-purposes","target":"_blank"}},[_vm._v("\n            "+_vm._s(_vm.$t('editRoleContext.billableRolesLink'))+"\n          ")])]):_vm._e()])]),_vm._v(" "),_c('ul',{staticClass:"context__menu context__menu--can-be-active"},[_vm._l((_vm.visibleRoles),function(role,index){return _c('li',{key:index,staticClass:"context__menu-item"},[_c('a',{staticClass:"context__menu-item-link context__menu-item-link--with-desc",class:{
            active: _vm.subject[_vm.roleValueColumn] === role.uid,
            disabled: role.isDeactivated,
          },on:{"click":function($event){!role.isDeactivated
              ? _vm.roleUpdate(role.uid, _vm.subject)
              : _vm.clickOnDeactivatedItem(role.uid)}}},[_c('span',{staticClass:"context__menu-item-title"},[_vm._v("\n            "+_vm._s(role.name)+"\n            "),(role.showIsBillable && role.isBillable)?_c('Badge',{attrs:{"color":"cyan","size":"small","bold":""}},[_vm._v(_vm._s(_vm.$t('common.billable'))+"\n            ")]):(
                role.showIsBillable &&
                !role.isBillable &&
                _vm.atLeastOneBillableRole
              )?_c('Badge',{attrs:{"color":"yellow","size":"small","bold":""}},[_vm._v(_vm._s(_vm.$t('common.free'))+"\n            ")]):_vm._e(),_vm._v(" "),(role.isDeactivated)?_c('i',{staticClass:"iconoir-lock"}):_vm._e()],1),_vm._v(" "),(role.description)?_c('div',{staticClass:"context__menu-item-description"},[_vm._v("\n            "+_vm._s(role.description)+"\n          ")]):_vm._e(),_vm._v(" "),(_vm.subject[_vm.roleValueColumn] === role.uid)?_c('i',{staticClass:"context__menu-active-icon iconoir-check"}):_vm._e()]),_vm._v(" "),_c(_vm.deactivatedClickModal(role),{ref:'deactivatedClickModal-' + role.uid,refInFor:true,tag:"component",attrs:{"v-if":_vm.deactivatedClickModal(role),"name":_vm.$t('editRoleContext.additionalRoles'),"workspace":_vm.workspace}})],1)}),_vm._v(" "),(_vm.allowRemovingRole)?_c('li',{staticClass:"context__menu-item context__menu-item--with-separator"},[_c('a',{staticClass:"context__menu-item-link context__menu-item-link--delete",on:{"click":function($event){return _vm.$emit('delete')}}},[_vm._v(_vm._s(_vm.$t('action.remove')))])]):_vm._e()],2)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }