var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Context',{ref:"context",staticClass:"hidings",attrs:{"max-height-if-outside-viewport":true},on:{"shown":function($event){return _vm.shown()}}},[_c('div',{staticClass:"hidings__head"},[(_vm.allowCoverImageField)?_c('FormGroup',{staticClass:"hidings__cover margin-bottom-2",attrs:{"small-label":"","label":_vm.$t('viewFieldsContext.coverField'),"required":""}},[_c('Dropdown',{attrs:{"value":_vm.coverImageField,"disabled":!_vm.$hasPermission(
            'database.table.view.update',
            _vm.view,
            _vm.database.workspace.id
          )},on:{"input":function($event){_vm.coverImageField !== $event &&
            _vm.$emit('update-cover-image-field', $event)}}},[_c('DropdownItem',{attrs:{"name":_vm.$t('viewFieldsContext.noCover'),"value":null}}),_vm._v(" "),_vm._l((_vm.fileFields),function(fileField){return _c('DropdownItem',{key:fileField.id,attrs:{"icon":fileField._.type.iconClass,"name":fileField.name,"value":fileField.id}})})],2)],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"hidings__search"},[_c('i',{staticClass:"hidings__search-icon iconoir-search"}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.query),expression:"query"}],ref:"search",staticClass:"hidings__search-input",attrs:{"type":"text","placeholder":_vm.$t('viewFieldsContext.search')},domProps:{"value":(_vm.query)},on:{"input":function($event){if($event.target.composing)return;_vm.query=$event.target.value}}})])],1),_vm._v(" "),_c('div',{directives:[{name:"auto-overflow-scroll",rawName:"v-auto-overflow-scroll"}],staticClass:"hidings__body"},[_c('ul',{staticClass:"hidings__list margin-top-1 margin-bottom-0"},_vm._l((_vm.filteredFields),function(field){return _c('li',{directives:[{name:"sortable",rawName:"v-sortable",value:({
          id: field.id,
          update: _vm.order,
          handle: '[data-field-handle]',
        }),expression:"{\n          id: field.id,\n          update: order,\n          handle: '[data-field-handle]',\n        }"}],key:field.id,staticClass:"hidings__item"},[_c('a',{staticClass:"hidings__item-handle",attrs:{"data-field-handle":""}}),_vm._v(" "),(_vm.allowHidingFields)?_c('SwitchInput',{attrs:{"small":"","value":!_vm.isHidden(field.id)},on:{"input":function($event){return _vm.updateFieldOptionsOfField(field, { hidden: !$event })}}},[_c('i',{staticClass:"switch__icon",class:field._.type.iconClass}),_vm._v(" "),_c('span',[_vm._v(_vm._s(field.name))])]):_c('div',{staticClass:"hidings__item-name"},[_c('i',{staticClass:"switch__icon",class:field._.type.iconClass}),_vm._v(" "),_c('span',[_vm._v(_vm._s(field.name))])])],1)}),0)]),_vm._v(" "),(_vm.allowHidingFields)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.query === ''),expression:"query === ''"}],staticClass:"hidings__footer"},[_c('Button',{staticClass:"hidings__footer-button",attrs:{"type":"secondary"},on:{"click":function($event){!_vm.noneSelected && _vm.updateAllFieldOptions({ hidden: true })}}},[_vm._v("\n      "+_vm._s(_vm.$t('viewFieldsContext.hideAll'))+"\n    ")]),_vm._v(" "),_c('Button',{attrs:{"type":"secondary"},on:{"click":function($event){!_vm.allSelected && _vm.updateAllFieldOptions({ hidden: false })}}},[_vm._v("\n      "+_vm._s(_vm.$t('viewFieldsContext.showAll'))+"\n    ")])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }