var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"api-token"},[_c('div',{staticClass:"api-token__head"},[_c('div',{staticClass:"api-token__info"},[_c('div',{staticClass:"api-token__name"},[_c('div',{staticClass:"api-token__name-content"},[_c('Editable',{ref:"rename",attrs:{"value":_vm.token.name},on:{"change":function($event){return _vm.updateToken(
                _vm.token,
                { name: $event.value },
                { name: $event.oldValue }
              )}}})],1),_vm._v(" "),_c('a',{ref:"contextLink",staticClass:"api-token__more",on:{"click":function($event){$event.preventDefault();return _vm.$refs.context.toggle(_vm.$refs.contextLink, 'bottom', 'right', 4)}}},[_c('i',{staticClass:"baserow-icon-more-horizontal"})]),_vm._v(" "),_c('Context',{ref:"context",attrs:{"overflow-scroll":true,"max-height-if-outside-viewport":true}},[_c('div',{staticClass:"api-token__key"},[_c('div',{staticClass:"api-token__key-name"},[_vm._v("\n              "+_vm._s(_vm.$t('apiToken.tokenPrefix'))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"api-token__key-value"},[(_vm.tokenVisible)?[_vm._v("\n                "+_vm._s(_vm.token.key)+"\n              ")]:[_vm._v("••••••••••••••••••••••••••••••••")]],2),_vm._v(" "),_c('a',{staticClass:"api-token__key-visible",attrs:{"title":_vm.$t('apiToken.showOrHide')},on:{"click":function($event){$event.preventDefault();_vm.tokenVisible = !_vm.tokenVisible}}},[_c('i',{class:_vm.tokenVisible ? 'iconoir-eye-off' : 'iconoir-eye-empty'})]),_vm._v(" "),_c('a',{staticClass:"api-token__key-copy",attrs:{"title":_vm.$t('apiToken.copyToClipboard')},on:{"click":function($event){;[_vm.copyTokenToClipboard(), _vm.$refs.copied.show()]}}},[_c('i',{staticClass:"iconoir-copy"}),_vm._v(" "),_c('Copied',{ref:"copied"})],1)]),_vm._v(" "),_c('ul',{staticClass:"context__menu"},[_c('li',{staticClass:"context__menu-item"},[_c('nuxt-link',{staticClass:"context__menu-item-link",attrs:{"to":{ name: 'database-api-docs' }}},[_c('i',{staticClass:"context__menu-item-icon iconoir-book"}),_vm._v("\n                "+_vm._s(_vm.$t('apiToken.viewAPIDocs'))+"\n              ")])],1),_vm._v(" "),_c('li',{staticClass:"context__menu-item"},[_c('a',{staticClass:"context__menu-item-link",class:{
                  'context__menu-item-link--loading': _vm.rotateLoading,
                },on:{"click":function($event){return _vm.rotateKey(_vm.token)}}},[_c('i',{staticClass:"context__menu-item-icon iconoir-refresh-double"}),_vm._v("\n                "+_vm._s(_vm.$t('apiToken.generateNewToken'))+"\n              ")])]),_vm._v(" "),_c('li',{staticClass:"context__menu-item"},[_c('a',{staticClass:"context__menu-item-link",on:{"click":function($event){return _vm.enableRename()}}},[_c('i',{staticClass:"context__menu-item-icon iconoir-edit-pencil"}),_vm._v("\n                "+_vm._s(_vm.$t('action.rename'))+"\n              ")])]),_vm._v(" "),_c('li',{staticClass:"context__menu-item"},[_c('a',{staticClass:"context__menu-item-link",class:{
                  'context__menu-item-link--loading': _vm.deleteLoading,
                },on:{"click":function($event){$event.preventDefault();return _vm.deleteToken(_vm.token)}}},[_c('i',{staticClass:"context__menu-item-icon iconoir-bin"}),_vm._v("\n                "+_vm._s(_vm.$t('action.delete'))+"\n              ")])])])])],1),_vm._v(" "),_c('div',{staticClass:"api-token__details"},[_c('div',{staticClass:"api-token__group"},[_vm._v(_vm._s(_vm.workspace.name))]),_vm._v(" "),_c('a',{staticClass:"api-token__expand",on:{"click":function($event){$event.preventDefault();_vm.open = !_vm.open}}},[_vm._v("\n          "+_vm._s(_vm.$t('apiToken.showDatabases'))+"\n          "),_c('i',{class:{
              'iconoir-nav-arrow-down': !_vm.open,
              'iconoir-nav-arrow-up': _vm.open,
            }})])])]),_vm._v(" "),_c('div',{staticClass:"api-token__permissions"},_vm._l((_vm.operations),function(operationName,operation){return _c('div',{key:operation,staticClass:"api-token__permission"},[_c('span',{staticClass:"margin-bottom-1"},[_vm._v(_vm._s(operationName))]),_vm._v(" "),_c('SwitchInput',{attrs:{"value":_vm.isActive(operation),"small":""},on:{"input":function($event){return _vm.toggle(operation, $event)}}})],1)}),0)]),_vm._v(" "),_c('div',{staticClass:"api-token__body",class:{ 'api-token__body--open': _vm.open }},_vm._l((_vm.databases),function(database){return _c('div',{key:database.id},[_c('div',{staticClass:"api-token__row"},[_c('div',{staticClass:"api-token__database"},[_vm._v("\n          "+_vm._s(database.name)+" "+_vm._s(database.id)+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"api-token__permissions"},_vm._l((_vm.operations),function(operationName,operation){return _c('div',{key:operation,staticClass:"api-token__permission"},[_c('SwitchInput',{attrs:{"value":_vm.isDatabaseActive(database, operation),"small":""},on:{"input":function($event){return _vm.toggleDatabase(database, _vm.databases, operation, $event)}}})],1)}),0)]),_vm._v(" "),_vm._l((database.tables),function(table){return _c('div',{key:table.id,staticClass:"api-token__row"},[_c('div',{staticClass:"api-token__table"},[_vm._v("\n          "+_vm._s(table.name)+" "),_c('small',[_vm._v("(id: "+_vm._s(table.id)+")")])]),_vm._v(" "),_c('div',{staticClass:"api-token__permissions"},_vm._l((_vm.operations),function(operationName,operation){return _c('div',{key:operation,staticClass:"api-token__permission"},[(
                _vm.$hasPermission(
                  `database.table.${operation}_row`,
                  table,
                  _vm.workspace.id
                )
              )?_c('Checkbox',{attrs:{"checked":_vm.isTableActive(table, database, operation)},on:{"input":function($event){return _vm.toggleTable(table, database, _vm.databases, operation, $event)}}}):_vm._e()],1)}),0)])})],2)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }