var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"auto-scroll",rawName:"v-auto-scroll",value:({
    enabled: () => _vm.isMultiSelectHolding,
    orientation: 'horizontal',
    speed: 4,
    padding: 10,
    onScroll: (speed) => {
      _vm.$emit('scroll', { pixelY: 0, pixelX: speed })
      return false
    },
  }),expression:"{\n    enabled: () => isMultiSelectHolding,\n    orientation: 'horizontal',\n    speed: 4,\n    padding: 10,\n    onScroll: (speed) => {\n      $emit('scroll', { pixelY: 0, pixelX: speed })\n      return false\n    },\n  }"}]},[_vm._l((_vm.groupByDividers),function({ left },index){return _c('div',{key:'group-by-divider-' + index,staticClass:"grid-view__group-by-divider",style:({ left: left + 'px' })})}),_vm._v(" "),_vm._l((_vm.groupByDividers),function({ groupBy, left },index){return _c('HorizontalResize',{key:'group-by-width-' + index,staticClass:"grid-view__head-group-width-handle",style:({ left: left + 'px' }),attrs:{"width":groupBy.width,"min":100},on:{"move":function($event){return _vm.moveGroupWidth(groupBy, _vm.view, $event)},"update":function($event){return _vm.updateGroupWidth(groupBy, _vm.view, _vm.database, _vm.readOnly, $event)}}})}),_vm._v(" "),_c('div',{staticClass:"grid-view__inner",style:({ 'min-width': _vm.width + 'px' })},[_c('GridViewHead',{attrs:{"database":_vm.database,"table":_vm.table,"view":_vm.view,"all-fields-in-table":_vm.allFieldsInTable,"visible-fields":_vm.visibleFields,"include-field-width-handles":_vm.includeFieldWidthHandles,"include-row-details":_vm.includeRowDetails,"include-add-field":_vm.includeAddField,"include-grid-view-identifier-dropdown":_vm.includeGridViewIdentifierDropdown,"include-group-by":_vm.includeGroupBy,"read-only":_vm.readOnly,"store-prefix":_vm.storePrefix},on:{"field-created":function($event){return _vm.$emit('field-created', $event)},"refresh":function($event){return _vm.$emit('refresh', $event)},"dragging":function($event){_vm.canOrderFields &&
          !$event.field.primary &&
          _vm.$refs.fieldDragging.start($event.field, $event.event)}}}),_vm._v(" "),_c('div',{directives:[{name:"auto-scroll",rawName:"v-auto-scroll",value:({
        enabled: () => _vm.isMultiSelectHolding,
        speed: 4,
        padding: 10,
        onScroll: (speed) => {
          _vm.$emit('scroll', { pixelY: speed, pixelX: 0 })
          return false
        },
      }),expression:"{\n        enabled: () => isMultiSelectHolding,\n        speed: 4,\n        padding: 10,\n        onScroll: (speed) => {\n          $emit('scroll', { pixelY: speed, pixelX: 0 })\n          return false\n        },\n      }"}],ref:"body",staticClass:"grid-view__body"},[_c('div',{staticClass:"grid-view__body-inner"},[_c('GridViewPlaceholder',{attrs:{"visible-fields":_vm.visibleFields,"view":_vm.view,"include-row-details":_vm.includeRowDetails,"include-group-by":_vm.includeGroupBy,"store-prefix":_vm.storePrefix}}),_vm._v(" "),(_vm.includeGroupBy && _vm.activeGroupBys.length > 0)?_c('GridViewGroups',{attrs:{"all-fields-in-table":_vm.allFieldsInTable,"group-by-value-sets":_vm.groupByValueSets,"store-prefix":_vm.storePrefix}}):_vm._e(),_vm._v(" "),(_vm.includeRowDetails || _vm.visibleFields.length > 0)?_c('GridViewRows',_vm._g({ref:"rows",attrs:{"view":_vm.view,"rendered-fields":_vm.fieldsToRender,"visible-fields":_vm.visibleFields,"all-fields-in-table":_vm.allFieldsInTable,"workspace-id":_vm.database.workspace.id,"decorations-by-place":_vm.decorationsByPlace,"left-offset":_vm.fieldsLeftOffset,"primary-field-is-sticky":_vm.primaryFieldIsSticky,"include-row-details":_vm.includeRowDetails,"include-group-by":_vm.includeGroupBy,"rows-at-end-of-groups":_vm.rowsAtEndOfGroups,"read-only":_vm.readOnly,"store-prefix":_vm.storePrefix}},_vm.$listeners)):_vm._e(),_vm._v(" "),(
            !_vm.readOnly &&
            (_vm.includeRowDetails || _vm.visibleFields.length > 0) &&
            _vm.$hasPermission(
              'database.table.create_row',
              _vm.table,
              _vm.database.workspace.id
            )
          )?_c('GridViewRowAdd',_vm._g({attrs:{"visible-fields":_vm.visibleFields,"include-row-details":_vm.includeRowDetails,"store-prefix":_vm.storePrefix}},_vm.$listeners)):_c('div',{staticClass:"grid-view__row-placeholder"})],1)]),_vm._v(" "),_c('div',{staticClass:"grid-view__foot"},[(_vm.includeRowDetails)?_c('div',{staticClass:"grid-view__foot-info"},[_vm._v("\n        "+_vm._s(_vm.$tc('gridView.rowCount', _vm.count, { count: _vm.count }))+"\n      ")]):_vm._e(),_vm._v(" "),_vm._l((_vm.visibleFields),function(field){return _c('div',{key:field.id,style:({ width: _vm.getFieldWidth(field.id) + 'px' })},[_c('GridViewFieldFooter',{attrs:{"database":_vm.database,"field":field,"view":_vm.view,"store-prefix":_vm.storePrefix}})],1)})],2)],1),_vm._v(" "),_c('GridViewFieldDragging',{ref:"fieldDragging",attrs:{"view":_vm.view,"fields":_vm.draggingFields,"offset":_vm.draggingOffset,"container-width":_vm.width,"read-only":_vm.readOnly,"store-prefix":_vm.storePrefix},on:{"scroll":function($event){return _vm.$emit('scroll', $event)}}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }