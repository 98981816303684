<template>
  <div
    class="subscription-checkout__plan"
    :class="{
      'subscription-checkout__plan--selected': selected,
    }"
    @click="$emit('selected', plan)"
  >
    <div class="subscription-checkout__plan-row">
      <div class="subscription-checkout__plan-name">
        {{ plan.name }}
      </div>
    </div>
    <div class="subscription-checkout__plan-row">
      ${{ plan.recurring_price['USD'] }} {{ $t('common.perUser') }} /
      {{ plan.billing_type }}
    </div>
    <div class="subscription-checkout__plan-row">
      <div class="subscription-checkout__plan-property">
        {{ $t('common.premium') }}
      </div>
      <div class="subscription-checkout__plan-value">
        <i
          class="fas"
          :class="{
            'iconoir-check color-success': plan.premium,
            'iconoir-cancel color-error': !plan.premium,
          }"
        ></i>
      </div>
    </div>
    <div v-if="isSelfHosted" class="subscription-checkout__plan-row">
      <div class="subscription-checkout__plan-property">
        {{ $t('common.enterprise') }}
      </div>
      <div class="subscription-checkout__plan-value">
        <i
          class="fas"
          :class="{
            'iconoir-check color-success': plan.enterprise,
            'iconoir-cancel color-error': !plan.enterprise,
          }"
        ></i>
      </div>
    </div>
    <div v-else class="subscription-checkout__plan-row">
      <div class="subscription-checkout__plan-property">
        {{ $t('common.rbac') }}
      </div>
      <div class="subscription-checkout__plan-value">
        <i
          class="fas"
          :class="{
            'iconoir-check color-success': plan.rbac,
            'iconoir-cancel color-error': !plan.rbac,
          }"
        ></i>
      </div>
    </div>
    <div class="subscription-checkout__plan-row">
      <div class="subscription-checkout__plan-property">
        {{ $t('common.prioritySupport') }}
      </div>
      <div class="subscription-checkout__plan-value">
        <i
          class="fas"
          :class="{
            'iconoir-check color-success': plan.priority_support,
            'iconoir-cancel color-error': !plan.priority_support,
          }"
        ></i>
      </div>
    </div>
    <div v-if="plan.rows_quota" class="subscription-checkout__plan-row">
      <div class="subscription-checkout__plan-property">
        {{ $t('common.rowLimit') }}
      </div>
      <div class="subscription-checkout__plan-value">
        {{ plan.rows_quota }}
      </div>
    </div>
    <div v-if="plan.storage_quota" class="subscription-checkout__plan-row">
      <div class="subscription-checkout__plan-property">
        {{ $t('common.storage') }}
      </div>
      <div class="subscription-checkout__plan-value">
        {{ plan.storage_quota }}{{ $t('common.gb') }}
      </div>
    </div>
    <div class="subscription-checkout__plan-row">
      <a :href="pricingLink" target="_blank" @click.stop>{{
        $t('checkoutPlan.detailedPricing')
      }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckoutPlan',
  props: {
    plan: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    pricingLink() {
      return (
        this.$config.PUBLIC_WEB_FRONTEND_URL +
        this.$nuxt.$router.resolve({
          name: 'pricing',
          query: { version: this.plan.type },
        }).href
      )
    },
    isSelfHosted() {
      return this.plan.type === 'self-hosted'
    },
  },
}
</script>
