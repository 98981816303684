var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-input",class:{
    'form-input--error': _vm.error,
    'form-input--monospace': _vm.monospace,
    'form-input--icon-left': _vm.iconLeft,
    'form-input--icon-right': _vm.iconRight,
    'form-input--loading': _vm.loading,
    'form-input--disabled': _vm.disabled,
    'form-input--small': _vm.size === 'small',
    'form-input--large': _vm.size === 'large',
    'form-input--suffix': _vm.hasSuffixSlot,
    'form-input--no-controls': _vm.removeNumberInputControls,
  },on:{"click":function($event){_vm.focusOnClick && _vm.focus()}}},[(_vm.iconLeft)?_c('i',{staticClass:"form-input__icon form-input__icon-left",class:_vm.iconLeft}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"form-input__wrapper"},[_c('input',{ref:"input",staticClass:"form-input__input",class:{ 'form-input__input--text-invisible': _vm.textInvisible },attrs:{"id":_vm.id,"disabled":_vm.disabled,"type":_vm.type,"min":_vm.type == 'number' && _vm.min > -1 ? parseInt(_vm.min) : false,"max":_vm.type == 'number' && _vm.max > -1 ? parseInt(_vm.max) : false,"placeholder":_vm.placeholder,"required":_vm.required,"autocomplete":_vm.autocomplete},domProps:{"value":_vm.fromValue(_vm.value)},on:{"blur":function($event){return _vm.$emit('blur', $event)},"click":function($event){return _vm.$emit('click', $event)},"focus":function($event){return _vm.$emit('focus', $event)},"keyup":function($event){return _vm.$emit('keyup', $event)},"keydown":function($event){return _vm.$emit('keydown', $event)},"keypress":function($event){return _vm.$emit('keypress', $event)},"input":function($event){_vm.$emit('input', _vm.toValue($event.target.value))},"mouseup":function($event){return _vm.$emit('mouseup', $event)},"mousedown":function($event){return _vm.$emit('mousedown', $event)}}}),_vm._v(" "),(_vm.iconRight)?_c('i',{staticClass:"form-input__icon form-input__icon-right",class:_vm.iconRight}):_vm._e()]),_vm._v(" "),(_vm.hasSuffixSlot)?_c('div',{staticClass:"form-input__suffix"},[_vm._t("suffix")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }