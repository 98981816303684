var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a',{ref:"contextLink",staticClass:"header__filter-link",class:{ 'active--primary': _vm.view.isShared },on:{"click":function($event){return _vm.$refs.context.toggle(_vm.$refs.contextLink, 'bottom', 'left', 4)}}},[_c('i',{staticClass:"header__filter-icon iconoir-share-android"}),_vm._v(" "),_c('span',{staticClass:"header__filter-name"},[_vm._v("\n      "+_vm._s(_vm.$t('shareViewLink.shareView', { viewTypeSharingLinkName: _vm.viewTypeSharingLinkName }))+"\n    ")])]),_vm._v(" "),_c('Context',{ref:"context",attrs:{"max-height-if-outside-viewport":true}},[(!_vm.view.isShared)?_c('div',{staticClass:"view-sharing__share-link"},[_c('div',{staticClass:"view-sharing__share-link-title"},[_vm._v("\n        "+_vm._s(_vm.$t('shareViewLink.shareViewTitle', { viewTypeSharingLinkName: _vm.viewTypeSharingLinkName }))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"view-sharing__share-link-description"},[_vm._v("\n        "+_vm._s(_vm.view.createShareViewText || _vm.$t('shareViewLink.shareViewText'))+"\n      ")]),_vm._v(" "),_c('div',{directives:[{name:"auto-overflow-scroll",rawName:"v-auto-overflow-scroll",value:(true),expression:"true"}],staticClass:"view-sharing__share-link-container"},[_c('ButtonText',{staticClass:"view-sharing__create-link",class:{ 'view-sharing__create-link--disabled': _vm.readOnly },attrs:{"tag":"a","icon":"baserow-icon-share view-sharing__create-link-icon"},on:{"click":function($event){$event.stopPropagation();!_vm.readOnly && _vm.updateView({ public: true })}}},[_vm._v("\n          "+_vm._s(_vm.$t('shareViewLink.shareViewLinkTitle', {
              viewTypeSharingLinkName: _vm.viewTypeSharingLinkName,
            }))+"\n        ")]),_vm._v(" "),_vm._l((_vm.additionalCreateShareLinkOptions),function(extraLink,i){return _c(extraLink,{key:i,tag:"component",staticClass:"view-sharing__create-link",attrs:{"view":_vm.view},on:{"update-view":_vm.forceUpdateView}})})],2)]):_c('div',{staticClass:"view-sharing"},[_c('div',{directives:[{name:"auto-overflow-scroll",rawName:"v-auto-overflow-scroll",value:(true),expression:"true"}],staticClass:"view-sharing--scrollable"},[(_vm.view.public)?_c('div',{staticClass:"view-sharing__shared-link"},[_c('div',{staticClass:"view-sharing__shared-link-title"},[_vm._v("\n            "+_vm._s(_vm.$t('shareViewLink.sharedViewTitle', { viewTypeSharingLinkName: _vm.viewTypeSharingLinkName }))+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"view-sharing__shared-link-description"},[_vm._v("\n            "+_vm._s(_vm.$t('shareViewLink.sharedViewDescription', {
                viewTypeSharingLinkName: _vm.viewTypeSharingLinkName,
              }))+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"view-sharing__shared-link-content"},[_c('div',{staticClass:"view-sharing__shared-link-box"},[_vm._v(_vm._s(_vm.shareUrl))]),_vm._v(" "),_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('shareViewLink.copyURL')),expression:"$t('shareViewLink.copyURL')"}],staticClass:"view-sharing__shared-link-action",on:{"click":function($event){return _vm.copyShareUrlToClipboard()}}},[_c('i',{staticClass:"iconoir-copy"}),_vm._v(" "),_c('Copied',{ref:"copied"})],1),_vm._v(" "),(!_vm.readOnly)?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('shareViewLink.generateNewUrl')),expression:"$t('shareViewLink.generateNewUrl')"}],staticClass:"view-sharing__shared-link-action",on:{"click":function($event){$event.preventDefault();return _vm.$refs.rotateSlugModal.show()}}},[_c('i',{staticClass:"iconoir-refresh-double"}),_vm._v(" "),_c('ViewRotateSlugModal',{ref:"rotateSlugModal",attrs:{"service":_vm.viewService,"view":_vm.view}})],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"view-sharing__shared-link-options"},[_c('div',{staticClass:"view-sharing__option"},[_c('SwitchInput',{attrs:{"small":"","value":_vm.view.public_view_has_password},on:{"input":_vm.toggleShareViewPassword}},[_c('i',{staticClass:"view-sharing__option-icon",class:[
                    _vm.view.public_view_has_password
                      ? 'iconoir-lock'
                      : 'iconoir-globe',
                  ]}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t(_vm.optionPasswordText)))])]),_vm._v(" "),(_vm.view.public_view_has_password)?_c('a',{staticClass:"view-sharing__option-change-password",on:{"click":function($event){$event.stopPropagation();return _vm.$refs.enablePasswordModal.show.apply(null, arguments)}}},[_vm._v("\n                "+_vm._s(_vm.$t('shareViewLink.ChangePassword'))+"\n                "),_c('i',{staticClass:"iconoir-edit-pencil"})]):_vm._e(),_vm._v(" "),_c('EnablePasswordModal',{ref:"enablePasswordModal",attrs:{"view":_vm.view}}),_vm._v(" "),_c('DisablePasswordModal',{ref:"disablePasswordModal",attrs:{"view":_vm.view}})],1),_vm._v(" "),_vm._l((_vm.additionalShareLinkOptions),function(component,i){return _c(component,{key:i,tag:"component",attrs:{"view":_vm.view},on:{"update-view":_vm.forceUpdateView}})})],2)]):_vm._e(),_vm._v(" "),_vm._l((_vm.additionalSharingSections),function(sharingSection,i){return _c(sharingSection,{key:i,tag:"component",attrs:{"view":_vm.view},on:{"update-view":_vm.forceUpdateView}})})],2),_vm._v(" "),(!_vm.readOnly)?_c('div',{staticClass:"view-sharing__shared-link-foot"},[(_vm.view.public)?_c('ButtonText',{staticClass:"view-sharing__shared-link-disable button-text--no-underline",attrs:{"tag":"a","type":"secondary","icon":"iconoir-cancel"},on:{"click":function($event){$event.stopPropagation();return _vm.updateView({ public: false })}}},[_vm._v("\n          "+_vm._s(_vm.$t('shareViewLink.disableLink'))+"\n        ")]):_c('ButtonText',{staticClass:"view-sharing__shared-link-disable button-text--no-underline",attrs:{"tag":"a","type":"secondary","icon":"baserow-icon-share"},on:{"click":function($event){$event.stopPropagation();!_vm.readOnly && _vm.updateView({ public: true })}}},[_vm._v("\n          "+_vm._s(_vm.$t('shareViewLink.shareViewLinkTitle', {
              viewTypeSharingLinkName: _vm.viewTypeSharingLinkName,
            }))+"\n        ")]),_vm._v(" "),_vm._l((_vm.additionalDisableSharedLinkOptions),function(comp,i){return _c(comp,{key:i,tag:"component",staticClass:"view-sharing__shared-link-disable",attrs:{"view":_vm.view},on:{"update-view":_vm.forceUpdateView}})})],2):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }