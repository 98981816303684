var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"modal-sidebar__head"},[_c('div',{staticClass:"modal-sidebar__head-icon-and-name"},[_c('i',{staticClass:"modal-sidebar__head-icon-and-name-icon iconoir-bin"}),_vm._v("\n      "+_vm._s(_vm.$t('trashSidebar.title'))+"\n    ")])]),_vm._v(" "),_c('ul',{staticClass:"trash-sidebar__groups"},_vm._l((_vm.workspaces),function(workspace){return _c('li',{key:'trash-group-' + workspace.id,staticClass:"trash-sidebar__group",class:{
        'trash-sidebar__group--active': _vm.isSelectedTrashWorkspace(workspace),
        'trash-sidebar__group--open':
          _vm.isSelectedTrashWorkspaceApplication(workspace),
        'trash-sidebar__group--trashed': workspace.trashed,
      }},[_c('a',{staticClass:"trash-sidebar__group-link",on:{"click":function($event){return _vm.emitIfNotAlreadySelectedTrashWorkspace(workspace)}}},[_c('i',{staticClass:"trash-sidebar__group-link-caret-right iconoir-nav-arrow-right"}),_vm._v(" "),_c('i',{staticClass:"trash-sidebar__group-link-caret-down iconoir-nav-arrow-down"}),_vm._v("\n        "+_vm._s(workspace.name ||
          _vm.$t('trashSidebar.unnamedWorkspace', { id: workspace.id }))+"\n      ")]),_vm._v(" "),_c('ul',{staticClass:"trash-sidebar__applications"},_vm._l((workspace.applications),function(application){return _c('li',{key:'trash-application-' + application.id,staticClass:"trash-sidebar__application",class:{
            'trash-sidebar__application--active': _vm.isSelectedApp(application),
            'trash-sidebar__application--trashed':
              workspace.trashed || application.trashed,
          }},[_c('a',{staticClass:"trash-sidebar__application-link",on:{"click":function($event){return _vm.emitIfNotAlreadySelectedTrashApplication(workspace, application)}}},[_vm._v(_vm._s(application.name || 'Unnamed application ' + application.id))])])}),0)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }