var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid-view__column",class:{
    'grid-view__column--filtered':
      !_vm.view.filters_disabled &&
      _vm.view.filters.findIndex((filter) => filter.field === _vm.field.id) !== -1,
    'grid-view__column--grouped':
      _vm.view.group_bys.findIndex((groupBy) => groupBy.field === _vm.field.id) !==
      -1,
    'grid-view__column--sorted':
      _vm.view.sortings.findIndex((sort) => sort.field === _vm.field.id) !== -1,
  },style:({ width: _vm.width + 'px' }),on:{"mousedown":function($event){return _vm.startDragging($event, _vm.field)}}},[_c('div',{staticClass:"grid-view__description",class:{ 'grid-view__description--loading': _vm.field._.loading }},[_c('i',{class:`grid-view__description-icon ${_vm.field._.type.iconClass}`}),_vm._v(" "),_c('div',{staticClass:"grid-view__description-name",attrs:{"title":_vm.field.name}},[_c('span',{ref:"quickEditLink",on:{"dblclick":function($event){return _vm.handleQuickEdit()}}},[_vm._v("\n        "+_vm._s(_vm.field.name)+"\n      ")])]),_vm._v(" "),(_vm.field.error)?_c('div',{staticClass:"grid-view__description-icon-error"},[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.field.error),expression:"field.error"}],staticClass:"iconoir-warning-triangle"})]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"grid-view__description-options"},[(_vm.field.description)?_c('HelpIcon',{attrs:{"tooltip":_vm.field.description || '',"tooltip-content-type":'plain',"tooltip-content-classes":[
          'tooltip__content--expandable',
          'tooltip__content--expandable-plain-text',
        ],"icon":'info-empty'}}):_vm._e(),_vm._v(" "),(!_vm.readOnly && _vm.showFieldContext)?_c('a',{ref:"contextLink",staticClass:"grid-view__description-icon-trigger",on:{"click":function($event){return _vm.$refs.context.toggle(_vm.$refs.contextLink, 'bottom', 'right', 0)},"mousedown":function($event){$event.stopPropagation();}}},[_c('i',{staticClass:"iconoir-nav-arrow-down"})]):_vm._e()],1),_vm._v(" "),(!_vm.readOnly)?_c('FieldContext',{ref:"context",attrs:{"database":_vm.database,"table":_vm.table,"view":_vm.view,"field":_vm.field,"all-fields-in-table":_vm.allFieldsInTable},on:{"update":function($event){return _vm.$emit('refresh', $event)},"delete":function($event){return _vm.$emit('refresh')}}},[(
          !_vm.field.primary &&
          !_vm.readOnly &&
          _vm.$hasPermission(
            'database.table.create_field',
            _vm.table,
            _vm.database.workspace.id
          )
        )?_c('li',{staticClass:"context__menu-item"},[_c('a',{ref:"insertLeftLink",staticClass:"context__menu-item-link",on:{"click":function($event){return _vm.$refs.insertFieldContext.toggle(_vm.$refs.insertLeftLink, 'left')}}},[_c('i',{staticClass:"context__menu-item-icon iconoir-arrow-left"}),_vm._v("\n          "+_vm._s(_vm.$t('gridViewFieldType.insertLeft'))+"\n        ")])]):_vm._e(),_vm._v(" "),(
          !_vm.field.primary &&
          !_vm.readOnly &&
          _vm.$hasPermission(
            'database.table.create_field',
            _vm.table,
            _vm.database.workspace.id
          )
        )?_c('li',{staticClass:"context__menu-item"},[_c('a',{ref:"insertRightLink",staticClass:"context__menu-item-link",on:{"click":function($event){return _vm.$refs.insertFieldContext.toggle(_vm.$refs.insertRightLink, 'right')}}},[_c('i',{staticClass:"context__menu-item-icon iconoir-arrow-right"}),_vm._v("\n          "+_vm._s(_vm.$t('gridViewFieldType.insertRight'))+"\n        ")]),_vm._v(" "),_c('InsertFieldContext',{ref:"insertFieldContext",attrs:{"table":_vm.table,"view":_vm.view,"from-field":_vm.field,"all-fields-in-table":_vm.allFieldsInTable,"database":_vm.database},on:{"field-created":function($event){return _vm.$emit('field-created', $event)},"move-field":function($event){return _vm.moveField($event)}}})],1):_vm._e(),_vm._v(" "),(
          !_vm.readOnly &&
          _vm.$hasPermission(
            'database.table.field.duplicate',
            _vm.field,
            _vm.database.workspace.id
          )
        )?_c('li',{staticClass:"context__menu-item"},[_c('a',{staticClass:"context__menu-item-link",on:{"click":function($event){;[_vm.$refs.duplicateFieldModal.toggle(), _vm.$refs.context.hide()]}}},[_c('i',{staticClass:"context__menu-item-icon iconoir-copy"}),_vm._v("\n          "+_vm._s(_vm.$t('gridViewFieldType.duplicate'))+"\n        ")]),_vm._v(" "),_c('DuplicateFieldModal',{ref:"duplicateFieldModal",attrs:{"table":_vm.table,"from-field":_vm.field,"all-fields-in-table":_vm.allFieldsInTable},on:{"field-created":function($event){return _vm.$emit('field-created', $event)},"move-field":function($event){return _vm.moveField($event)}}})],1):_vm._e(),_vm._v(" "),_c('li'),_vm._v(" "),(
          _vm.canFilter &&
          _vm.$hasPermission(
            'database.table.view.create_filter',
            _vm.view,
            _vm.database.workspace.id
          )
        )?_c('li',{staticClass:"context__menu-item"},[_c('a',{staticClass:"context__menu-item-link",on:{"click":function($event){return _vm.createFilter($event, _vm.view, _vm.field)}}},[_c('i',{staticClass:"context__menu-item-icon iconoir-filter"}),_vm._v("\n          "+_vm._s(_vm.$t('gridViewFieldType.createFilter'))+"\n        ")])]):_vm._e(),_vm._v(" "),(
          _vm.getCanSortInView(_vm.field) &&
          _vm.$hasPermission(
            'database.table.view.create_sort',
            _vm.view,
            _vm.database.workspace.id
          )
        )?_c('li',{staticClass:"context__menu-item"},[_c('a',{staticClass:"context__menu-item-link",on:{"click":function($event){return _vm.createSort($event, _vm.view, _vm.field, 'ASC')}}},[_c('i',{staticClass:"context__menu-item-icon iconoir-sort-down"}),_vm._v("\n          "+_vm._s(_vm.$t('gridViewFieldType.sortField'))+"\n          "),(_vm.getSortIndicator(_vm.field, 0) === 'text')?[_vm._v(_vm._s(_vm.getSortIndicator(_vm.field, 1)))]:_vm._e(),_vm._v(" "),(_vm.getSortIndicator(_vm.field, 0) === 'icon')?_c('i',{class:_vm.getSortIndicator(_vm.field, 1)}):_vm._e(),_vm._v(" "),_c('i',{staticClass:"iconoir-arrow-right"}),_vm._v(" "),(_vm.getSortIndicator(_vm.field, 0) === 'text')?[_vm._v(_vm._s(_vm.getSortIndicator(_vm.field, 2)))]:_vm._e(),_vm._v(" "),(_vm.getSortIndicator(_vm.field, 0) === 'icon')?_c('i',{class:_vm.getSortIndicator(_vm.field, 2)}):_vm._e()],2)]):_vm._e(),_vm._v(" "),(
          _vm.getCanSortInView(_vm.field) &&
          _vm.$hasPermission(
            'database.table.view.create_sort',
            _vm.view,
            _vm.database.workspace.id
          )
        )?_c('li',{staticClass:"context__menu-item"},[_c('a',{staticClass:"context__menu-item-link",on:{"click":function($event){return _vm.createSort($event, _vm.view, _vm.field, 'DESC')}}},[_c('i',{staticClass:"context__menu-item-icon iconoir-sort-down"}),_vm._v("\n          "+_vm._s(_vm.$t('gridViewFieldType.sortField'))+"\n          "),(_vm.getSortIndicator(_vm.field, 0) === 'text')?[_vm._v(_vm._s(_vm.getSortIndicator(_vm.field, 2)))]:_vm._e(),_vm._v(" "),(_vm.getSortIndicator(_vm.field, 0) === 'icon')?_c('i',{class:_vm.getSortIndicator(_vm.field, 2)}):_vm._e(),_vm._v(" "),_c('i',{staticClass:"iconoir-arrow-right"}),_vm._v(" "),(_vm.getSortIndicator(_vm.field, 0) === 'text')?[_vm._v(_vm._s(_vm.getSortIndicator(_vm.field, 1)))]:_vm._e(),_vm._v(" "),(_vm.getSortIndicator(_vm.field, 0) === 'icon')?_c('i',{class:_vm.getSortIndicator(_vm.field, 1)}):_vm._e()],2)]):_vm._e(),_vm._v(" "),(
          !_vm.field.primary &&
          _vm.$hasPermission(
            'database.table.view.update_field_options',
            _vm.view,
            _vm.database.workspace.id
          )
        )?_c('li',{staticClass:"context__menu-item"},[_c('a',{staticClass:"context__menu-item-link",on:{"click":function($event){return _vm.hide($event, _vm.view, _vm.field)}}},[_c('i',{staticClass:"context__menu-item-icon iconoir-eye-off"}),_vm._v("\n          "+_vm._s(_vm.$t('gridViewFieldType.hideField'))+"\n        ")])]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.includeFieldWidthHandles)?_c('HorizontalResize',{staticClass:"grid-view__description-width",attrs:{"width":_vm.width,"min":100},on:{"move":function($event){return _vm.moveFieldWidth(_vm.field, $event)},"update":function($event){return _vm.updateFieldWidth(_vm.field, _vm.view, _vm.database, _vm.readOnly, $event)}}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }