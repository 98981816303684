var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Context',{ref:"context",attrs:{"overflow-scroll":true,"max-height-if-outside-viewport":true}},[_c('div',{staticClass:"context__menu-title"},[_vm._v(_vm._s(_vm.view.name)+" ("+_vm._s(_vm.view.id)+")")]),_vm._v(" "),_c('ul',{staticClass:"context__menu"},[(
        _vm.hasValidExporter &&
        _vm.$hasPermission(
          'database.table.run_export',
          _vm.table,
          _vm.database.workspace.id
        )
      )?_c('li',{staticClass:"context__menu-item"},[_c('a',{staticClass:"context__menu-item-link",on:{"click":function($event){return _vm.exportView()}}},[_c('i',{staticClass:"context__menu-item-icon iconoir-share-ios"}),_vm._v("\n        "+_vm._s(_vm.$t('viewContext.exportView'))+"\n      ")])]):_vm._e(),_vm._v(" "),(
        _vm.$hasPermission(
          'database.table.import_rows',
          _vm.table,
          _vm.database.workspace.id
        )
      )?_c('li',{staticClass:"context__menu-item"},[_c('a',{staticClass:"context__menu-item-link",on:{"click":function($event){return _vm.importFile()}}},[_c('i',{staticClass:"context__menu-item-icon iconoir-import"}),_vm._v("\n        "+_vm._s(_vm.$t('viewContext.importFile'))+"\n      ")])]):_vm._e(),_vm._v(" "),(
        _vm.$hasPermission(
          'database.table.view.duplicate',
          _vm.view,
          _vm.database.workspace.id
        )
      )?_c('li',{staticClass:"context__menu-item"},[_c('a',{staticClass:"context__menu-item-link",class:{ 'context__menu-item-link--loading': _vm.duplicateLoading },on:{"click":function($event){return _vm.duplicateView()}}},[_c('i',{staticClass:"context__menu-item-icon iconoir-copy"}),_vm._v("\n        "+_vm._s(_vm.$t('viewContext.duplicateView'))+"\n      ")])]):_vm._e(),_vm._v(" "),_vm._l((_vm.changeViewOwnershipTypeMenuItems),function(changeViewOwnershipTypeComponent,index){return _c('li',{key:'view-ownership-type-' + index},[_c(changeViewOwnershipTypeComponent,{tag:"component",attrs:{"view":_vm.view,"database":_vm.database}})],1)}),_vm._v(" "),(
        _vm.$hasPermission(
          'database.table.create_webhook',
          _vm.table,
          _vm.database.workspace.id
        )
      )?_c('li',{staticClass:"context__menu-item"},[_c('a',{staticClass:"context__menu-item-link",on:{"click":function($event){return _vm.openWebhookModal()}}},[_c('i',{staticClass:"context__menu-item-icon iconoir-globe"}),_vm._v("\n        "+_vm._s(_vm.$t('viewContext.webhooks'))+"\n      ")])]):_vm._e(),_vm._v(" "),(
        _vm.$hasPermission(
          'database.table.view.update',
          _vm.view,
          _vm.database.workspace.id
        )
      )?_c('li',{staticClass:"context__menu-item"},[_c('a',{staticClass:"context__menu-item-link",on:{"click":function($event){return _vm.enableRename()}}},[_c('i',{staticClass:"context__menu-item-icon iconoir-edit-pencil"}),_vm._v("\n        "+_vm._s(_vm.$t('viewContext.renameView'))+"\n      ")])]):_vm._e(),_vm._v(" "),(
        _vm.$hasPermission(
          'database.table.view.delete',
          _vm.view,
          _vm.database.workspace.id
        )
      )?_c('li',{staticClass:"context__menu-item context-menu-item--with-separator"},[_c('a',{staticClass:"context__menu-item-link context__menu-item-link--delete",class:{ 'context__menu-item-link--loading': _vm.deleteLoading },on:{"click":function($event){return _vm.deleteView()}}},[_c('i',{staticClass:"context__menu-item-icon iconoir-bin"}),_vm._v("\n        "+_vm._s(_vm.$t('viewContext.deleteView'))+"\n      ")])]):_vm._e()],2),_vm._v(" "),_c('ExportTableModal',{ref:"exportViewModal",attrs:{"database":_vm.database,"table":_vm.table,"view":_vm.view}}),_vm._v(" "),_c('ImportFileModal',{ref:"importFileModal",attrs:{"database":_vm.database,"table":_vm.table,"fields":_vm.fields}}),_vm._v(" "),_c('WebhookModal',{ref:"webhookModal",attrs:{"database":_vm.database,"table":_vm.table,"fields":_vm.fields}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }