var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"onboarding-tool-preview"},[_c('div',{ref:"inner",staticClass:"onboarding-tool-preview__inner"},[_c('div',{staticClass:"onboarding-tool-preview__highlight",class:{
        'onboarding-tool-preview__highlight--hidden':
          _vm.highlightPosition.width === 0 && _vm.highlightPosition.height === 0,
      },style:({
        left: `${_vm.highlightPosition.left}px`,
        top: `${_vm.highlightPosition.top}px`,
        width: `${_vm.highlightPosition.width}px`,
        height: `${_vm.highlightPosition.height}px`,
      })}),_vm._v(" "),_c('div',{staticClass:"layout"},[_c('div',{staticClass:"layout__col-1"},[_c('Sidebar',{ref:"sidebar",attrs:{"workspaces":_vm.workspaces,"selected-workspace":_vm.selectedWorkspace,"applications":_vm.applications}})],1),_vm._v(" "),_c('div',{staticClass:"layout__col-2"},[(_vm.col2Component)?_c(_vm.col2Component,{tag:"component",attrs:{"data":_vm.data,"selected-workspace":_vm.selectedWorkspace,"applications":_vm.applications}}):_vm._e()],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }